<template>
  <div class="container">
    <Breadcrumb class="bg-transparent" :breadcrumbList="[
      { 'name': 'Admin Dashboard', 'link': $router.resolve({ name: 'admin' }).href },
      { 'name': 'Accounting' }
    ]"/>
    <div class="main-container">
      <h1 class=" mb-2"><strong>Accounting</strong></h1>
      <div class="row mt-5 justify-content-center">
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4" v-if="this.$store.getters.hasRole('ROLE_ADMIN_ACCOUNTING')">
          <Cards type="img" image="CheckoutOrder/crypto.svg" title="Crypto" :route="'/admin/accounting/crypto'" class="white-icon"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cards from '../../../components/Admin/AdminCards'
import { mapActions } from 'vuex'
import Breadcrumb from '../../../components/Breadcrumbs/Breadcrumb'

export default {
  name: 'AdminAccountingLanding',
  components: {
    Breadcrumb,
    Cards
  },
  mounted () {
    this.buildUser()
  },
  methods: {
    ...mapActions([
      'buildUser'
    ])
  }
}
</script>

<style scoped>
::v-deep .white-icon img {
  filter: brightness(0) invert(1);
}
</style>
