<template>
  <div>
    <div class="container">
      <Breadcrumb class="bg-transparent" :breadcrumbList="[
      { 'name': 'Admin Dashboard', 'link': $router.resolve({ name: 'admin' }).href },
      { 'name': 'Accounting', 'link': $router.resolve({ name: 'admin_accounting' }).href },
      { 'name': 'Crypto'}
    ]"/>
    </div>

    <div class="main-container">
      <h1 class="mb-4 pb-1 font-weight-bold">Crypto</h1>

      <div class="container bg-white p-3">
        <div class="d-flex">
          <div class="p-2">
            <b-form-datepicker id="from-datepicker" :date-format-options="date.options" v-model="date.start" class="mb-3 mb-lg-0" placeholder="Start Date"></b-form-datepicker>
          </div>
          <div class="p-2">
            <b-form-datepicker id="to-datepicker" :date-format-options="date.options" v-model="date.end" class="mb-3 mb-lg-0" placeholder="End Date"></b-form-datepicker>
          </div>
          <div class="p-2 flex-grow-1 text-right">
            <button class="btn btn-primary" @click="exportPayments" :disabled="downloadingData">Export
              <b-spinner class="ml-2" small label="small Spinner" v-if="downloadingData"/>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '../../../components/Breadcrumbs/Breadcrumb'
import apiService from '../../../apiService'

export default {
  name: 'AdminAccountingCrypto',
  components: {
    Breadcrumb
  },
  data () {
    return {
      downloadingData: false,
      date: {
        start: null,
        end: null,
        options: {
          'year': 'numeric',
          'month': 'numeric',
          'day': 'numeric'
        }
      }
    }
  },
  methods: {
    exportPayments () {
      this.downloadingData = true

      let data = []
      if (this.date.start !== null) {
        data.push('startDate=' + this.date.start)
      }

      if (this.date.end !== null) {
        data.push('endDate=' + this.date.end)
      }

      apiService.exportPayments('crypto', data).then(response => {
        let date = new Date()
        let month = date.getMonth() + 1
        let monthFormat = month < 10 ? '0' + month : month
        let dateFormat = date.getFullYear() + '-' + monthFormat + '-' + date.getDate() + '-' + date.getHours() + '-' + date.getMinutes()

        let url = window.URL.createObjectURL(new Blob([response.data]))
        let link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'crypto-' + dateFormat + '.csv')
        document.body.appendChild(link)
        link.click()
      }).finally(() => {
        this.downloadingData = false
      })
    }
  }
}
</script>
